<template>
  <div class="information-page">
    <div class="information-main"  v-if="list.length">
      <!--information-item start-->
      <div class="information-item" v-for="(item, index) in list" :key="index">
        <div class="item-con">
          <div class="t">{{ item.content }}</div>
          <div class="d">{{ item.createtime }}</div>
        </div>
        <div class="item-more color_orange" @click="$refs.message.open(item.id)">查看详情>></div>
      </div>
      <!--information-item end-->
      <div class="paging">
        <el-pagination
          background
          @current-change="changePage"
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <bind-message ref="message"></bind-message>
  </div>
</template>

<script>
import BindMessage from '../../components/BindMessage.vue';
// import 'swiper/css/swiper.css'
export default {
  components: { BindMessage },
  data() {
    return {
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
    };
  },
  methods:{
    changePage (e) {
      this.page = e
      this.getmessageList()
    },
    //获取消息列表
    getmessageList () {
      this.$axios.messageList({
        page:this.page
      }).then(res=>{
        this.list = res.data.data
        this.total = res.data.total
      })
    }
  },
  created () {
    this.getmessageList()
  }
};
</script>

<style scoped lang="less">
.information-main {
  background: #fff;
  padding: 20px 40px;
  .information-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
    padding: 15px 0px;
    cursor: pointer;
    .item-more {
      width: 100px;
      min-width: 100px;
      max-width: 100px;
      text-align: right;
      font-size: 14px;
    }
    .item-con {
      width: calc(100% - 100px);
    }
    .t {
      font-size: 16px;
      color: #666;
      line-height: 1.6;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .d {
      color: #999;
      font-size: 14px;
      line-height: 2;
      margin-top: 5px;
    }
  }
}
</style>
