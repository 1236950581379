<template>
  <el-dialog title="详情" width="600px" :visible.sync="dialogTableVisible">
    <div class="">
      <div class="center m-b-20">{{ details.createtime }}</div>
      <div class="">{{ details.content }}</div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogTableVisible:false,
      details:{}
    };
  },
  methods: {
    open (id) {
      this.$axios.messageDetail({id}).then(res=>{
        this.details = res.data
        this.dialogTableVisible = true
      })
      
    }
  },
};
</script>

<style scoped lang="less"></style>
